'use client';
import { ArrowUpRightIcon } from 'lucide-react';
import Image from 'next/image';
import Link from 'next/link';
import SphereLogo from 'public/landing-page/sphere-logo.svg?url';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../Button/Button';
import { DesktopLinks } from './DesktopLinks';
import { MobileLinks } from './MobileLinks';

export function NavbarRoot({ children }: { children: React.ReactNode }) {
  return (
    <div className='mx-auto flex x-padding-page w-full max-w-screen-xl flex-row items-center gap-x-12 md:py-4 h-[3.6875rem] md:h-[4.3125rem]'>
      {children}
    </div>
  );
}

export function Logo() {
  const { i18n } = useTranslation();

  return (
    <Link href={`/${i18n.language}`} className='shrink-0'>
      <p className='sr-only'>Sphere</p>
      <Image
        src={SphereLogo}
        alt='Sphere Logo'
        className='h-auto w-[5.375rem] hover:cursor-pointer md:w-[7.5625rem] lg:mr-2'
      />
    </Link>
  );
}

export function NavbarDesktop() {
  return (
    <div className='hidden lg:block'>
      <DesktopLinks />
    </div>
  );
}

export function NavbarCTA() {
  return (
    <div className='hidden lg:flex lg:items-center lg:gap-x-4'>
      <Button
        asChild
        variant='secondary'
        className='h-9 text-[0.9375rem] leading-none'
      >
        <Link href='/login'>Dashboard</Link>
      </Button>
      <Button
        asChild
        variant='default'
        className='h-9 text-[0.9375rem] leading-none gap-x-0.5'
      >
        <Link href='/ramp'>
          Sphere Ramp <ArrowUpRightIcon className='w-5 h-auto -mr-1' />
        </Link>
      </Button>
    </div>
  );
}

export function NavbarMobile() {
  return (
    <div className='ml-auto lg:hidden'>
      <MobileLinks />
    </div>
  );
}
